import {useParams, Link} from 'react-router-dom'
import Services from "./services.json"
import {Helmet} from "react-helmet"
const styles = {
    section: {
      fontSize: "18px",
      color: "#292b2c",
      backgroundColor: "#fff",
      padding: "0 20px"
    },
    wrapper: {
      textAlign: "center",
      margin: "0 auto",
      marginTop: "50px"
    },
    img: {
        float: "left",
        margin: "15px",
        maxWidth: "40vw"
        
      },
    
      p: {
        textAlign: "justify",
      },
      div:{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
      ul:{ 
        color: "",
        fontStyle: "normal",
        lineHeight:"2",
      }
  }
function Service()
{
    const params = useParams();
    const {slug} = params;
    console.warn(slug)
    const data = Services.find(item => item.slug === slug);
    var divStyle = {
        background: 'url(' + data.header + ') 50% 50%/cover no-repeat',
    }
    var headingColor ={
        // color:'#FFB673',
        fontFamily: 'Montserrat sans-serif',
    }
    var ulStyle = {
        marginTop:'0px',
        paddingTop:'10px',
    }
    return(
    <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Apricot - {data.title}</title>
          <link rel="canonical" href="https://apricot.us/services" />
          <meta name="description" content="Apricot Info Tech offers a wide range of IT consulting services, specializing in areas such as big data, business intelligence, data analytics, and data transformation. Our expertise also extends to infrastructure management, managed services, CRM, and ERP solutions. Explore how our comprehensive services can transform your business and drive success in the digital age." />
          <meta name="keywords" content="" />
        </Helmet>
        <section className="page-title overly-2" style={divStyle}>
            <div className='overlay my-5'>
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block">
                                {/* <span className="text-white">{data.title}</span> */}
                                <h1 className="text-capitalize text-lg" >{data.title}</h1>
                                <ul className="list-inline breadcumb-nav" style={ulStyle}>
                                    <li className="list-inline-item"><Link to="/" className="text-white">Home</Link></li>
                                    <li className="list-inline-item"><span className="text-white">/</span></li>
                                    <li className="list-inline-item"><Link to="/services" className="text-white-50">Services</Link></li>
                                    <li className="list-inline-item"><span className="text-white">/</span></li>
                                    <li className="list-inline-item"><a href="#" className="text-white-50">{data.title}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section case-study">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>{data.h1}</h1>
                        {/* <div className="case-img">
                            <img src={data.image} alt="" style={styles.img} className="img-fluid" />
                        </div> */}
                        <p style={styles.p}>{data.p1}</p>
                        <p style={styles.p}>{data.p2}</p>
                        <p style={styles.p}>{data.p3}</p>
                        <p style={styles.p}>{data.p4}</p>
                        <div style={styles.ul} dangerouslySetInnerHTML={{__html: data.info}} />
                        <p style={styles.p}>{data.p5}</p>
                        <p style={styles.p}>{data.p6}</p>
                    </div>
                </div>
            </div>
        </section>
    </div>

    )
}

export default Service;