import { Link } from "react-router-dom"
import {Helmet} from "react-helmet"
var ulStyle = {
  marginTop:'0px',
  paddingTop:'10px',
}
export default function Services() {
  return(
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Apricot - Services</title>
          <link rel="canonical" href="https://apricot.us/services" />
          <meta name="description" content="Apricot Info Tech offers a wide range of IT consulting services, specializing in areas such as big data, business intelligence, data analytics, and data transformation. Our expertise also extends to infrastructure management, managed services, CRM, and ERP solutions. Explore how our comprehensive services can transform your business and drive success in the digital age." />
          <meta name="keywords" content="" />
        </Helmet>
        <section className="page-title bg-1">
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  {/* <span className="text-white">Our services</span> */}
                  <h1 className="text-capitalize mt-5 text-lg">What We Do</h1>
                  <ul className="list-inline breadcumb-nav" style={ulStyle}>
                    <li className="list-inline-item"><Link to="/" className="text-white">Home</Link></li>
                    <li className="list-inline-item"><span className="text-white">/</span></li>
                    <li className="list-inline-item"><a href="#" className="text-white-50">Our services</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section service-2">
          <div className="container">
            <p>Our comprehensive range of services encompasses the entire spectrum of IT, spanning from strategic planning and meticulous execution to ongoing support and maintenance. By immersing ourselves in our clients' operations, we forge a deep understanding of their unique business needs, enabling us to craft tailored solutions that seamlessly blend innovation with practicality.</p>
            <div className="row">
              <div className="col-lg-6">
                <div className="service-img mb-5 mb-lg-0">
                  <img src="assets/images/service/service-1.jpg" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="service-info ">
                  <span className="text-color font-weight-bold">01</span>
                  <h3 className="text-md mb-4 mt-2">
                    Custom Software development
                  </h3>
                  <p>At Apricot, we tailor the software development process to your specific business needs to deliver high-quality software on time and within budget.
                    <br/>
                    We create software with long-term business value – tailored uniquely to your business processes and adjustable to future needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="content-padding position-relative">
              <div className="row">
                <div className="col-lg-6">
                  <div className="service-info mb-5 mb-lg-0">
                    <span className="text-color font-weight-bold">02</span>
                    <h3 className="text-md mb-4 mt-2">
                      Software Maintenance
                    </h3>
                    <p>We ensure smooth functioning and relevancy of your software via continuous performance monitoring, proactive optimization and fast issue resolution, delivery of new features and integrations.</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="service-img">
                    <img src="assets/images/service/service-3.jpg" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="service-img mb-5 mb-lg-0">
                  <img src="assets/images/service/service-4.jpg" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="service-info">
                  <span className="text-color font-weight-bold">03</span>
                  <h3 className="text-md mb-4 mt-2">
                    Application Services
                  </h3>
                  <p>Application support and maintenance services are aimed to ensure that your apps are highly available, reliable and keep up with your ever-evolving business needs.<br/>

                    Apricot provides app support and maintenance services. We help our customers from 30+ industries free up their IT staff and fully focus on the creative side of the business to achieve sustainable growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="section service">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="heading">
                  <h2 className="mb-4">Industry Leading Managed <br />Services &amp; Consulting Solutions</h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-6">
                <div className="service-item-list">
                  <i className="ti-panel" />
                  <p>Digital transformation consulting</p>
                </div>
                <div className="service-item-list">
                  <i className="ti-desktop" />
                  <p>IT strategy <br/> consulting</p>
                </div>
                <div className="service-item-list">
                  <i className="ti-light-bulb" />
                  <p>IT assessment & Technology consulting</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item-list">
                  <i className="ti-mobile" />
                  <p>IT operations <br/> consulting</p>
                </div>
                <div className="service-item-list">
                  <i className="ti-check-box" />
                  <p>IT project & program <br/> management </p>
                </div>
                <div className="service-item-list">
                  <i className="ti-dashboard" />
                  <p>Application <br/> implementation</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item-list">
                  <i className="ti-headphone-alt" />
                  <p>Application <br/> modernization </p>
                </div>
                <div className="service-item-list">
                  <i className="ti-bar-chart-alt" />
                  <p>Application  <br/> support .</p>
                </div>
                <div className="service-item-list">
                  <i className="ti-cloud-up" />
                  <p>IT infrastructure   <br/> management</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section case-study bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="case-study-content text-center mb-5">
                  <h2 className="mb-4">How we work</h2>
                </div>
              </div>
            </div>
            <div className="case-timeline">
              <div className="case-timeline-divider" />
              <div className="case-timeline-dot" />
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="case-img ">
                    <img src="assets/images/about/business.svg" alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="case-content">
                    <h4 className="mb-3">Business analysis</h4>
                    <p align="justify">Our business analytics transform your needs into prioritized software requirements and aid in conceptualizing your software.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="case-timeline">
              <div className="case-timeline-divider" />
              <div className="case-timeline-dot" />
              <div className="row align-items-center">  
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="case-content">
                    <h4 className="mb-3">Software development process and management </h4>
                    <p align="justify">Our software development process and management utilize an iterative development approach, enabling us to launch the first software version within 3-4 months and continuously evolve it every 1-4 weeks.</p>
                  </div>
                </div>
                <div className="col-lg-6  order-1 order-lg-2">
                  <div className="case-img">
                    <img src="assets/images/about/devlopment.svg" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <div className="case-timeline">
              <div className="case-timeline-divider" />
              <div className="case-timeline-dot" />
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="case-img">
                    <img src="assets/images/about/qa.svg" alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="case-content">
                    <h4 className="mb-3">QA & Testing</h4>
                    <p align="justify">To ensure software quality, we employ a comprehensive testing strategy that includes unit tests, code reviews, functionality, performance, usability, integration, compatibility, and security testing.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="case-timeline">
              <div className="case-timeline-divider" />
              <div className="case-timeline-dot" />
              <div className="row align-items-center">
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="case-content">
                    <h4 className="mb-3">Risk management</h4>
                    <p align="justify">We define and estimate risks related to the project budget, delivery time, cybersecurity, new technologies and personnel. Then, we prepare a mitigation plan for each risk, monitor risks and report to responsible project stakeholders to keep all parties informed about existing risks and their states and ensure the consistency of risk management actions.</p>
                  </div>
                </div>
                <div className="col-lg-6  order-1 order-lg-2">
                  <div className="case-img">
                    <img src="assets/images/about/risk.svg" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="case-timeline">
              <div className="case-timeline-divider" />
              <div className="case-timeline-dot" />
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="case-img">
                    <img src="assets/images/about/change.svg" alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="case-content">
                    <h4 className="mb-3">Change management</h4>
                    <p align="justify">We review the required software changes with all parties concerned, analyzing how the changes will impact software business logic and adapting it accordingly before any change implementation.</p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="case-timeline">
              <div className="case-timeline-divider" />
              <div className="case-timeline-dot" />
              <div className="row align-items-center">
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="case-content">
                    <h4 className="mb-3">Project delivery</h4>
                    <p align="justify">Upon completion of the software, our customers retain complete ownership of the intellectual property rights. Additionally, we provide maintenance and support services to facilitate continuous software improvement.</p>
                    {/* <ul className="list-unstyled mt-4 mb-5">
                      <li><i className="ti-check mr-3" />Turn-key software.</li>
                      <li><i className="ti-check mr-3" />Documentation (technical requirements and design).</li>
                      <li><i className="ti-check mr-3" />User manual.</li>
                      <li><i className="ti-check mr-3" />Setup program.</li>
                      <li><i className="ti-check mr-3" />Installation and maintenance instructions.</li>
                      <li><i className="ti-check mr-3" />Source code.</li>
                      <li><i className="ti-check mr-3" />Warranty.</li>
                    </ul> */}
                    {/* <p align="justify">Our customers retain full intellectual property rights over software.</p>
                    <p align="justify">Upon software completion, we offer maintenance and support services to help continuously improve software.</p> */}
                  </div>
                </div>
                <div className="col-lg-6  order-1 order-lg-2">
                  <div className="case-img">
                    <img src="assets/images/about/delivery.svg" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <p align="justify">We identify and assess risks related to project budget, delivery time, cybersecurity, new technologies, and personnel. Mitigation plans are created for each risk, and ongoing monitoring and reporting keep stakeholders informed. We also analyze software changes' impact on business logic and make necessary adaptations before implementing any changes.</p>
          </div>
        </section>
        <section className="video-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="heading feature-list">
                  <h2 className="my-4">What our customer receives ?</h2>
                  <ul className="list-unstyled mt-4 mb-5">
                    <li><i className="ti-check mr-3" />Fully operational software solution.</li>
                    <li><i className="ti-check mr-3" />Technical requirements and design documentation.</li>
                    <li><i className="ti-check mr-3" />User manual for easy usage.</li>
                    <li><i className="ti-check mr-3" />Setup program for convenient installation.</li>
                    <li><i className="ti-check mr-3" />Instructions for installation and maintenance.</li>
                    <li><i className="ti-check mr-3" />Source code of the software.</li>
                    <li><i className="ti-check mr-3" />Warranty for added assurance.</li>
                    <li><i className="ti-check mr-3" />Full ownership of the intellectual property rights of the software.</li>
                    <li><i className="ti-check mr-3" />Maintenance and support services.</li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cta-2 bg-light">
          <div className="container">
            <div className="cta-block py-5">
              <div className="row align-items-center ">
                <div className="col-lg-8">
                  <span className="text-color">For Every type business</span>
                  <h2 className="mt-2 mb-4 mb-lg-0">Entrust your project to our best team of professionals</h2>
                </div>
                <div className="col-lg-4">
                  <Link to="/contact" className="btn btn-main btn-round-full float-lg-right">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
}