import { Link } from "react-router-dom"
import {Helmet} from "react-helmet"
export default function Home() {
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Apricot - Home</title>
                    <link rel="canonical" href="https://apricot.us/" />
                    <meta name="description" content="Apricot is a provider of IT consulting and software development services, ever since we have helped non-IT organizations and software product companies improve business performance and quickly win new customers." />
                    <meta name="keywords" content="" />
                </Helmet>
                {/* Slider Start */}
                <section className="banner d-flex align-items-center">
                <div className="banner-img-part" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-md-12 col-xl-8">
                                <div className="block">
                                <span className="text-uppercase text-sm letter-spacing ">The most powerful Solution</span>
                                <h1 className="mb-3 mt-3">Digital experience with Apricot</h1>
                                <p className="mb-5">We transform businesses with powerful and adaptable digital solutions that satisfy the needs of today and unlock the opportunities of tomorrow.</p>
                                <Link to="/about" className="btn btn-main">Learn more about Apricot<i className="fa fa-angle-right ml-2" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="section about">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-4 col-md-6 ">
                            <div className="about-item mb-5 mb-lg-5">
                            <div className="icon">
                                <i className="ti-light-bulb" />
                            </div>
                            <div className="content">
                                <h4 className="mt-3 mb-3">Custom Effective Software development</h4>
                                <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                                <a href="#"> Read More </a>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="about-item mb-5 mb-lg-5">
                            <div className="icon">
                                <i className="ti-panel" />
                            </div>
                            <div className="content">
                                <h4 className="mt-3 mb-3">Analyse Your Expensees On Every Device</h4>
                                <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                                <a href="#"> Read More </a>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="about-item">
                            <div className="icon">
                                <i className="ti-headphone-alt" />
                            </div>
                            <div className="content">
                                <h4 className="mt-3 mb-3">Creating a dedicated IT business growth</h4>
                                <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                                <a href="#"> Read More </a>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section> */}
                <section className="section process">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-5">
                            <div className="process-block pl-4">
                            <span className="text-uppercase text-sm letter-spacing">Why choose us</span>
                            <h2 className="mb-4 mt-3">We help you to make work easy</h2>
                            <p className="mb-2" align="justify">We can develop reliable, scalable and secure software solutions for any OS, browser and device. We bring together deep industry expertise and the latest IT advancements to deliver custom solutions and products that perfectly fit the needs and behavior of their users. </p>
                            <p className="mb-2" align="justify">Moreover, maintaining security is of paramount importance. We implement industry-standard security practices to safeguard user data, prevent unauthorized access, and address potential vulnerabilities. This includes following secure coding practices, regularly updating software components, conducting thorough testing, and staying informed about emerging security threats.</p>
                            <p className="mb-4" align="justify">By combining expertise, cutting-edge technology, and a deep understanding of user needs, we can create software solutions that meet the demands of today's dynamic digital landscape.</p>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xs-12 col-md-12">
                            <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="icon-block text-center mb-4 mb-lg-0">
                                <i className="ti-light-bulb" />
                                <h5>IT Consulting</h5>
                                <p>IT Consulting for your business needs</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="icon-block text-center mt-4 mb-4 mb-lg-0">
                                <i className="ti-panel" />
                                <h5>Managed Services</h5>
                                <p>A complete Managed Services solution for business</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="icon-block text-center">
                                <i className="ti-search" />
                                <h5>CRM</h5>
                                <p>End to End Customer Relationship Management solutions</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="icon-block text-center mt-4">
                                <i className="ti-rocket" />
                                <h5>ERP</h5>
                                <p>Complete ERP Solution to ease of access from anywhere</p>
                                </div>
                            </div>
                            </div>
                        </div>	
                        </div>
                    </div>
                </section>
                <section className="section cta">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-5">
                            <div className="cta-content bg-white p-5 rounded">
                            <h3 className="mb-4">Assurance Service In Software<span className="text-color-primary"> Testing</span> </h3>
                            <p className="mb-30">An Independent Validation and Testing services from SISAR. Helps to reduce software development efforts</p>
                            {/* <a href="#" className="btn btn-main">Portfolio<i className="fa fa-angle-right ml-2" /></a> */}
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
                {/* section portfolio start */}
                <section className="section portfolio pb-0">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-8">
                            <div className="heading">
                            <h2 className="mb-4">We’ve Done Lot’s of Work, Let’s Check Some From Here</h2>
                            <p>We have the best experts to elevate your business to the next level, try is and you will see! We have the best experts to elevate your </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row portfolio-gallery">
                        <div className="col-lg-4 col-md-6">
                            <div className="portflio-item position-relative mb-4">
                            <a href="/industries/automotive">
                                <img src="assets/images/industries-small/automotive.png" alt="" className="img-fluid w-100" />
                                <div className="overlay-item"><i className="ti-link" /></div>	
                                <div className="portfolio-item-content">
                                <h3 className="mb-0 text-white">Automotive</h3>
                                <p className="text-white-50">Industry</p>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="portflio-item position-relative mb-4">
                            <a href="/industries/banking">
                                <img src="assets/images/industries-small/banking.png" alt="" className="img-fluid w-100" />
                                <div className="overlay-item">
                                <i className="ti-link" />
                                </div>
                                <div className="portfolio-item-content">
                                <h3 className="mb-0 text-white">Banking</h3>
                                <p className="text-white-50">Industry</p>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="portflio-item position-relative mb-4">
                            <a href="/service/it-counsulting">
                                <img src="assets/images/services-small/it-consulting.png" alt="" className="img-fluid w-100" />
                                <div className="overlay-item">
                                <i className="ti-link" />
                                </div>
                                <div className="portfolio-item-content">
                                <h3 className="mb-0 text-white">IT Consulting</h3>
                                <p className="text-white-50">Services</p>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="portflio-item position-relative mb-4">
                            <a href="/service/data-analytics">
                                <img src="assets/images/services-small/data-analytics.png" alt="" className="img-fluid w-100" />
                                <div className="overlay-item">
                                <i className="ti-link" />
                                </div>
                                <div className="portfolio-item-content">
                                <h3 className="mb-0 text-white">Data Analytics</h3>
                                <p className="text-white-50">Services</p>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="portflio-item position-relative  mb-4">
                            <a href="/service/devops">
                                <img src="assets/images/services-small/devops.png" alt="" className="img-fluid w-100" />
                                <div className="overlay-item">
                                <i className="ti-link" />
                                </div>
                                <div className="portfolio-item-content">
                                <h3 className="mb-0 text-white">DevOps</h3>
                                <p className="text-white-50">Services</p>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="portflio-item position-relative mb-4">
                            <a href="/industries/communications">
                                <img src="assets/images/industries-small/communications.png" alt="" className="img-fluid w-100" />
                                <div className="overlay-item">
                                <i className="ti-link" />
                                </div>
                                <div className="portfolio-item-content">
                                <h3 className="mb-0 text-white">Communications</h3>
                                <p className="text-white-50">Industry</p>
                                </div>
                            </a>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
                {/* section portfolio END */}
                {/* section Counter Start */}
                {/* <section className="section counter">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item text-center mb-5 mb-lg-0">
                            <h2 className="mb-0"><span className="counter-stat font-weight-bold">1730</span> +</h2>
                            <p>Project Done</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item text-center mb-5 mb-lg-0">
                            <h2 className="mb-0"><span className="counter-stat font-weight-bold">125 </span>M </h2>
                            <p>User Worldwide</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item text-center mb-5 mb-lg-0">
                            <h2 className="mb-0"><span className="counter-stat font-weight-bold">39</span></h2>
                            <p>Availble Country</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter-item text-center">
                            <h2 className="mb-0"><span className="counter-stat font-weight-bold">14</span></h2>
                            <p>Award Winner </p>
                            </div>
                        </div>
                        </div>
                    </div>
                </section> */}
                {/* section Counter End  */}
                
                {/* footer Start */}
                
            </div>
        );
    }
