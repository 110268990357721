import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom"

export default function Navbar() {
  const location = window.location.origin
console.log(location);
const logoUrl = window.location.origin+'/assets/images/apricot.png'
  return (
    <nav className="navbar navbar-expand-lg py-4 navigation header-padding " id="navbar">
      <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={logoUrl} alt="" className="img-fluid mylogo"  />
          </Link>
          
          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse text-center" id="navbarsExample09">
            <ul className="navbar-nav m-auto">
                <li className="nav-item active">
                  <CustomLink className="nav-link" to="/">Home <span className="sr-only">(current)</span></CustomLink>
                </li>
                <li className="nav-item">
                  <CustomLink className="nav-link" to="/about">About</CustomLink>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="dropdown06" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Services</a>
                  <ul className="dropdown-menu" aria-labelledby="dropdown06">
                    <div className="row">
                      <div className="col-md-4">
                        <li><CustomLink className="dropdown-item" to="/service/it-counsulting">IT Consulting</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/service/big-data">Big Data</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/service/business-intelligence">Business Intelligence</CustomLink></li>
                      </div>
                      <div className="col-md-4">
                        <li><CustomLink className="dropdown-item" to="/service/data-analytics">Data Analytics</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/service/infrastructure-management">Infrastructure<br/>Management</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/service/digital-transformation">Digital Transformation</CustomLink></li>
                      </div>
                      <div className="col-md-4">
                        <li><CustomLink className="dropdown-item" to="/service/managed-services">Managed Services</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/service/devops">DevOps</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/service/crm">CRM</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/service/erp">ERP</CustomLink></li>
                      </div>
                    </div>
                  </ul>
                </li>
                
                
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="dropdown05" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Industries</a>
                  <ul className="dropdown-menu" aria-labelledby="dropdown05">
                    <div className="row">
                      <div className="col-md-4">
                        <li><CustomLink className="dropdown-item" to="/industries/automotive">Automotive</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/industries/banking">Banking</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/industries/financial-services">Financial Services</CustomLink></li>
                      </div>
                      <div className="col-md-4">
                        <li><CustomLink className="dropdown-item" to="/industries/telecommunications ">Telecommunications</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/industries/healthcare">Healthcare</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/industries/insurance">Insurance</CustomLink></li>
                      </div>
                      <div className="col-md-4">
                        <li><CustomLink className="dropdown-item" to="/industries/public-sector">Public Sector</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/industries/retail">Retail</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/industries/utilities">Utilities</CustomLink></li>
                        <li><CustomLink className="dropdown-item" to="/industries/it-services">IT Services</CustomLink></li>
                      </div>
                    </div>
                  </ul>
                </li>
                <li className="nav-item">
                  <CustomLink className="nav-link" to="/clients">Clients</CustomLink>
                </li>
                <li className="nav-item">
                  <CustomLink className="nav-link" to="/careers">Careers</CustomLink>
                </li>
                <li className="nav-item">
                  <CustomLink className="nav-link" to="/contact">Contact</CustomLink>
                </li>
            </ul>
            <Link to="/contact" className="btn btn-solid-border d-none d-lg-block">Lets Talk<i className="fa fa-angle-right ml-2" /></Link>
          </div>
      </div>
    </nav>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "nav-item active" : "nav-item"}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}