import { Link } from "react-router-dom"

export default function Footer() {
  return (
    <footer className="footer section">
        <div className="container mt-3">
            <div className="row">
            <div className="col-lg-4 mr-auto col-sm-6">
                <div className="widget mb-5 mb-lg-0">
                <div className="logo mb-4">
                    <h3>Apricot</h3>
                </div>
                <p align="justify">Apricot is a provider of IT consulting and software development services, ever since we have helped non-IT organizations and software product companies improve business performance and quickly win new customers.</p>
                </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
                <div className="widget mb-5 mb-lg-0">
                <h4 className="text-capitalize mb-4">Company</h4>
                <ul className="list-unstyled footer-menu lh-35">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    {/* <li><Link to="/clients">Clients</Link></li>
                    <li><Link to="/careers">Careers</Link></li> */}
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
                </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
                <div className="widget mb-5 mb-lg-0">
                <h4 className="text-capitalize mb-4">Support</h4>
                <ul className="list-unstyled footer-menu lh-35">
                    <li><a href="#">Terms &amp; Conditions</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                    <li><a href="#">Support</a></li>
                    <li><a href="#">FAQ</a></li>
                </ul>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget widget-contact mb-5 mb-lg-0">
                <h4 className="text-capitalize mb-4">Get in Touch</h4>
                <h6><a href="mailto:info@apricot.us"> <i className="ti-headphone-alt mr-3" />info@apricot.us</a></h6>
                <h6><a href="tel:1800-9688644"> <i className="ti-mobile mr-3" />+1(800) 968-8644 (Toll Free)</a></h6>
                
                <ul className="list-inline footer-socials mt-5">
                    <li className="list-inline-item"><a href="#"><i className="ti-facebook mr-2" /></a></li>
                    <li className="list-inline-item"><a href="#"><i className="ti-twitter mr-2" /></a></li>
                    <li className="list-inline-item"><a href="#"><i className="ti-linkedin mr-2 " /></a></li>
                </ul>
                </div>
            </div>
            </div>
            <div className="footer-btm py-4">
            <div className="row">
                <div className="col-lg-6">
                <div className="copyright">
                    © Copyright {(new Date().getFullYear())} -  Reserved to <span className="text-color">Apricot Info Tech Inc.</span>
                </div>
                </div>
            </div>
            </div>
        </div>
    </footer>
  )
}
