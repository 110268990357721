import { Link } from "react-router-dom"
import {Helmet} from "react-helmet"
var ulStyle = {
  marginTop:'0px',
  paddingTop:'10px',
}
export default function Contact() {
  return(
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Apricot - Contact</title>
          <link rel="canonical" href="https://apricot.us/contact" />
          <meta name="description" content="" />
          <meta name="keywords" content="" />
        </Helmet>
        <section className="page-title bg-1">
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  {/* <span className="text-white">Contact Us</span> */}
                  <h1 className="text-capitalize mt-5 text-lg">Get in Touch</h1>
                  <ul className="list-inline breadcumb-nav" style={ulStyle}>
                    <li className="list-inline-item"><a href="index.html" className="text-white">Home</a></li>
                    <li className="list-inline-item"><span className="text-white">/</span></li>
                    <li className="list-inline-item"><a href="#" className="text-white-50">Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact form start */}
        <section className="contact-form-wrap section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <span className="text-color">Send a message</span>
                <h3 className="text-md mb-5">Contact Form</h3>
                <div className="row">
                  <div className="col-lg-8">
                    <form id="contact-form" className="contact__form " method="post" action="mail.php">
                      {/* form message */}
                      <div className="row">
                        <div className="col-12">
                          <div className="alert alert-success contact__msg" style={{display: 'none'}} role="alert">
                            Your message was sent successfully.
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-group">
                        <select className="form-control" id="exampleFormControlSelect1">
                          <option>I Need help in ...</option>
                          <option>Software Design</option>
                          <option>Development cycle</option>
                          <option>Software Development</option>
                          <option>Maintenance</option>
                          <option>Process Query</option>
                          <option>Cost and Duration</option>
                          <option>Modal Delivery</option>
                        </select>
                      </div> */}
                      <div className="form-group">
                        <input name="subject" id="subject" type="text" className="form-control" placeholder="Your Subject" />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input name="fname" id="fname" type="text" className="form-control" placeholder="First Name" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input name="lname" id="lname" type="text" className="form-control" placeholder="Last Name" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input name="email" id="email" type="email" className="form-control" placeholder="Email Address" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input name="mobile" id="mobile" type="number" className="form-control" placeholder="Contact Number" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <input name="company" id="company" type="text" className="form-control" placeholder="Company Name" />
                      </div>
                      <div className="form-group-2 mb-4">
                        <textarea name="message" id="message" className="form-control" rows={4} placeholder="How Apricot Info Tech Inc can help you?" defaultValue={""} />
                      </div>
                      <button className="btn btn-main" name="submit" type="submit">Send Message</button>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <div className="short-info mt-5 mt-lg-0">
                      <ul className="list-unstyled">
                        <li>
                          <h5>Call Us</h5>
                          +1(800) 968-8644 (Toll Free)
                        </li>
                        <li>
                          <h5>Email Us</h5>
                          info@apricot.us
                        </li>
                        <li>
                          <h5>Location Map</h5>
                          300 Delaware Avenue<br/>Suite #2211<br/>Wilmington<br/>DE 19801
                        </li>
                      </ul>
                      <ul className="social-icons list-inline mt-5">
                        <li className="list-inline-item">
                          <a href="#"><i className="fab fa-facebook-f" /></a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#"><i className="fab fa-twitter" /></a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#"><i className="fab fa-linkedin-in" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="google-map mt-90 mb-5">
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.6888032825077!2d-75.55269434899911!3d39.74664070394175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6fd40168fffff%3A0x7def3aae31ad727e!2s300%20Delaware%20Ave%20%232211%2C%20Wilmington%2C%20DE%2019801%2C%20USA!5e0!3m2!1sen!2sin!4v1677666590186!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.6888032825077!2d-75.55269434899911!3d39.74664070394175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6fd40168fffff%3A0x7def3aae31ad727e!2s300%20Delaware%20Ave%20%232211%2C%20Wilmington%2C%20DE%2019801%2C%20USA!5e0!3m2!1sen!2sin!4v1677666590186!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="contact-content pl-lg-5 mt-5 mt-lg-0 mb-5 mb-lg-0">
                  <h4 className="mb-4">Corporate Office</h4>
                  <ul className="address-block list-unstyled">
                    <li>
                      <i className="ti-location-pin mr-3" />North Main Street,Brooklyn Australia
                    </li>
                    <li>
                      <i className="ti-email mr-3" />Email: contact@mail.com
                    </li>
                    <li>
                      <i className="ti-mobile mr-3" />Phone:+88 01672 506 744
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contact-info mb-5 mb-lg-0 mt-md-5 mt-lg-0">
                  <h4 className="mb-4">USA Location</h4>
                  <ul className="address-block list-unstyled">
                    <li>
                      <i className="ti-location-pin mr-3" />North Main Street,Brooklyn Australia
                    </li>
                    <li>
                      <i className="ti-email mr-3" />Email: contact@mail.com
                    </li>
                    <li>
                      <i className="ti-mobile mr-3" />Phone:+88 01672 506 744
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contact-info ">
                  <h4 className="mb-4">Europe</h4>
                  <ul className="address-block list-unstyled">
                    <li>
                      <i className="ti-location-pin mr-3" />North Main Street,Brooklyn Australia
                    </li>
                    <li>
                      <i className="ti-email mr-3" />Email: contact@mail.com
                    </li>
                    <li>
                      <i className="ti-mobile mr-3" />Phone:+88 01672 506 744
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    );
  }