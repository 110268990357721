import { Link } from "react-router-dom"
import {Helmet} from "react-helmet"

const styles = {
    section: {
      fontSize: "18px",
      color: "#292b2c",
      backgroundColor: "#fff",
      padding: "0 20px"
    },
    wrapper: {
      textAlign: "center",
      margin: "0 auto",
      marginTop: "50px"
    },
    img: {
        float: "left",
        margin: "15px",
        maxWidth: "40vw"
        
      },
    
      p: {
        textAlign: "justify",
      }
  }
  var ulStyle = {
    marginTop:'0px',
    paddingTop:'10px',
  }
export default function Clients() {
  return(
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Apricot - Clients</title>
          <link rel="canonical" href="https://apricot.us/clients" />
          <meta name="description" content="" />
          <meta name="keywords" content="" />
        </Helmet>
        <section className="page-title bg-1">
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  {/* <span className="text-white">Clients</span> */}
                  <h1 className="text-capitalize mt-5 text-lg">Clients</h1>
                  <ul className="list-inline breadcumb-nav" style={ulStyle}>
                    <li className="list-inline-item"><a href="/" className="text-white">Home</a></li>
                    <li className="list-inline-item"><span className="text-white">/</span></li>
                    <li className="list-inline-item"><a href="#" className="text-white-50">Clients</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact form start */}
        <section className="section awards bg-light">
            <div className="container">
                <h2 className="mb-4">Welcome to our client section!</h2>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="why-content">
                            
                            <p style={styles.p} className="mb-1">At Apricot Info Tech , we pride ourselves on delivering exceptional services to our clients. Our goal is to establish strong relationships with our clients and to exceed their expectations every step of the way.</p>

                            <p style={styles.p} className="mb-1">We understand that every client is unique, and that’s why we work closely with each of them to tailor our services to their specific needs. Our team of experienced professionals is dedicated to providing personalized solutions that are both efficient and effective.</p>

                            <p style={styles.p} className="mb-1">We value the feedback of our clients and continuously strive to improve our services based on their suggestions. We believe in transparency and open communication, and we make it our priority to keep our clients informed throughout the entire process.</p>

                            <p style={styles.p} className="mb-1">We are committed to maintaining the highest standards of integrity, professionalism, and quality in everything we do. Our clients can trust us to deliver exceptional results, on time and within budget.</p>

                            <p style={styles.p} className="mb-1">Thank you for choosing Apricot Info Tech . We look forward to working with you and helping you achieve your goals.</p>
                        </div>
                    </div>
                    {/* <div className="col-lg-6">
                        <img style={styles.img} src="assets/images/clients.jpg" alt="" className="img-fluid" />
                    </div> */}
                </div>
                
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-3 col-sm-4">
                    <div className="award-img-block my-4 mb-lg-0">
                        <div className="award-img">
                        <img src="assets/images/clients/ibm.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4">
                    <div className="award-img-block my-4 mb-lg-0">
                        <div className="award-img">
                        <img src="assets/images/clients/jj.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4">
                    <div className="award-img-block my-4 mb-lg-0">
                        <div className="award-img">
                        <img src="assets/images/clients/ifds.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4">
                    <div className="award-img-block my-4 mb-lg-0">
                        <div className="award-img">
                        <img src="assets/images/clients/kastech.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4">
                    <div className="award-img-block my-4 mb-lg-0">
                        <div className="award-img">
                        <img src="assets/images/clients/hp.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4">
                    <div className="award-img-block my-4 mb-lg-0">
                        <div className="award-img">
                        <img src="assets/images/clients/info-plus.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4">
                    <div className="award-img-block my-4 mb-lg-0">
                        <div className="award-img">
                        <img src="assets/images/clients/tekniti.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4">
                    <div className="award-img-block my-4 mb-lg-0">
                        <div className="award-img">
                        <img src="assets/images/clients/kacey.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4">
                    <div className="award-img-block my-4 mb-lg-0">
                        <div className="award-img">
                        <img src="assets/images/clients/loyalty-methods.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
      </div>
    );
  }