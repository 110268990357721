import { Link } from "react-router-dom"
const styles = {
    section: {
      fontSize: "18px",
      color: "#292b2c",
      backgroundColor: "#fff",
      padding: "0 20px"
    },
    wrapper: {
      textAlign: "center",
      margin: "0 auto",
      marginTop: "50px"
    },
    img: {
        float: "left",
        margin: "15px",
        maxWidth: "40vw"
        
      },
    
      p: {
        textAlign: "justify",
      }
  }
export default function Career() {
  return(
      <div>
        {/* <section className="page-title bg-1">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block text-center">
                  <h1 className="text-capitalize mb-5 text-lg">Page Not Found</h1>
                  <ul className="list-inline breadcumb-nav">
                    <li className="list-inline-item"><a href="/" className="text-white">Home</a></li>
                    <li className="list-inline-item"><span className="text-white">/</span></li>
                    <li className="list-inline-item"><a href="#" className="text-white-50">Page Not Found</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="section awards bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <img src="assets/images/404.png" alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-12">
                    <Link to="/" className="btn btn-main">Go To HomePage<i className="fa fa-angle-right ml-2" /></Link>
                    </div>
                </div>
            </div>
        </section>
      </div>
    );
  }