import { Link } from "react-router-dom"
import {Helmet} from "react-helmet"
const styles = {
  section: {
    fontSize: "18px",
    color: "#292b2c",
    backgroundColor: "#fff",
    padding: "0 20px"
  },
  wrapper: {
    textAlign: "center",
    margin: "0 auto",
    marginTop: "50px"
  },
  img: {
      float: "left",
      margin: "15px",
      maxWidth: "40vw"
      
    },
  
    p: {
      textAlign: "justify",
    }
}
var ulStyle = {
  marginTop:'0px',
  paddingTop:'10px',
}
export default function About() {
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Apricot - About</title>
                    <link rel="canonical" href="https://apricot.us/about" />
                    <meta name="description" content="Discover how Apricot Info Tech combines expert IT consulting with innovative software solutions to empower businesses. From strategic IT guidance to cutting-edge software development, we deliver comprehensive services tailored to your needs." />
                    <meta name="keywords" content="" />
                </Helmet>
                <section className="page-title bg-1">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="block">
                          {/* <span className="text-white">About Us</span> */}
                          <h1 className="text-capitalize mt-5 text-lg">Our Company</h1>
                          <ul className="list-inline breadcumb-nav" style={ulStyle}>
                            <li className="list-inline-item"><Link to="/" className="text-white">Home</Link></li>
                            <li className="list-inline-item"><span className="text-white">/</span></li>
                            <li className="list-inline-item"><Link to="#" className="text-white-50">About Us</Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="section awards bg-light">
                  <div className="container">
                      <h2 className="mb-4">Unleashing Innovation: Empowering the Future with Cutting-Edge Tech Solutions</h2>
                      <div className="row">
                          <div className="col-lg-6">
                              <div className="why-content">
                                <p style={styles.p} className="mb-3">Our unwavering passion for technology and its ability to revolutionize businesses drives everything we do. As a tightly knit team of seasoned IT professionals, we are fully devoted to assisting our clients in unlocking the potential of technology to achieve their goals and soar to new heights.</p>

                                <p style={styles.p} className="mb-3">With a wealth of collective experience across diverse industries including finance, healthcare, education, and manufacturing, our team possesses invaluable insights and expertise. We continually stay abreast of the latest trends and cutting-edge technologies, ensuring that our clients remain at the forefront of their respective industries and embrace the transformative power of progress.</p>

                                <p style={styles.p} className="mb-3">At Apricot Info Tech, we understand that solid relationships are the foundation of success. We pride ourselves on fostering strong connections with our clients, acting as their trusted partners on their technological journey. Exceptional customer service lies at the heart of our ethos, as we go above and beyond to deliver an unparalleled experience. Our unwavering commitment to punctuality, budget adherence, and uncompromising quality guarantees that every project we undertake is executed to perfection.</p>

                                <p style={styles.p} className="mb-3">At Apricot Info Tech, we are not just technology enthusiasts – we are dedicated catalysts of growth, propelling our clients towards unprecedented success with unwavering commitment and boundless innovation.</p>

                                <p style={styles.p} className="mb-3">Our driving force is the profound desire to make a positive impact on our clients' businesses. We channel our passion and expertise into every endeavor, constantly seeking to surpass expectations and create lasting value.</p>
                              </div>
                          </div>
                          <div className="col-lg-6">
                              <img src="assets/images/about/about-2.jpg" alt="" className="img-fluid" />
                          </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h4>Mission</h4>
                          <p style={styles.p} className="mb-3">Our aim is to provide innovative and reliable technology solutions that empower businesses to thrive in the digital age. We are committed to delivering cutting-edge software development, IT consulting, and digital transformation services that drive efficiency, enhance productivity, and accelerate growth for our clients. Our goal is to be a trusted partner, delivering tailored solutions that address our clients' unique challenges and enable them to stay ahead of the competition.</p>
                        </div>
                        <div className="col-lg-6">
                          <h4>Vision</h4>
                          <p style={styles.p} className="mb-3">We aim to create a positive impact on businesses across industries by leveraging emerging technologies to unlock their full potential. We strive to build long-term partnerships with our clients, helping them navigate the ever-changing technological landscape and achieve sustainable success. Through continuous innovation, exceptional service, and a passionate team, we envision Apricot Info Tech as the go-to choice for organizations seeking transformative digital solutions.</p>
                        </div>
                      </div>
                    </div>
                </section>
                {/* <section className="section ">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="heading text-center">
                          <h2>We help entreprenuers <br />starts up and turn <span className="text-color">their ideas into</span> produtcs</h2>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="counter-item counter--style text-center mb-5 mb-lg-0">
                              <i className="ti-thumb-up" />
                              <h2 className="mb-0 mt-3"><span className="counter-stat font-weight-bold">1730</span> +</h2>
                              <p>Project Done</p>
                            </div>  
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="counter-item counter--style text-center mb-5 mb-lg-0">
                              <i className="ti-face-smile" />
                              <h2 className="mb-0 mt-3"><span className="counter-stat font-weight-bold">125 </span>M </h2>
                              <p>User Worldwide</p>
                            </div>  
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="counter-item counter--style text-center">
                              <i className="ti-cup" />
                              <h2 className="mb-0 mt-3"><span className="counter-stat font-weight-bold">14</span></h2>
                              <p>Award Winner </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section> */}
                {/* <section className="video-block bg-light">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="heading feature-list">
                          <h2 className="mb-4">Growing Software Company.</h2>
                          <p align="justify">Apricot Info Tech is a provider of IT consulting and software development services, ever since we have helped non-IT organizations and software product companies improve business performance and quickly win new customers.</p>
                          <ul className="list-unstyled mt-4 mb-5">
                            <li><i className="ti-check mr-3" />Digital transformation consulting</li>
                            <li><i className="ti-check mr-3" />IT strategy consulting</li>
                            <li><i className="ti-check mr-3" />IT assessment</li>
                            <li><i className="ti-check mr-3" />IT operations consulting</li>
                            <li><i className="ti-check mr-3" />IT project and program management</li>
                            <li><i className="ti-check mr-3" />Technology consulting</li>
                            <li><i className="ti-check mr-3" />Application implementation</li>
                            <li><i className="ti-check mr-3" />Application modernization</li>
                            <li><i className="ti-check mr-3" />Application support</li>
                            <li><i className="ti-check mr-3" />IT infrastructure management</li>
                          </ul>
                          <Link to="/services" className="btn btn-main">All Services<i className="fa fa-angle-right ml-2" /></Link>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="video-img">
                          <img src="assets/images/about/img-34.png" alt="" className="img-fluid" />
                          <Link data-video-id="sXoKSD8QJEA" className="video-play"><i className="ti-control-play" /></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </section> */}
                {/* <section className="section team">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-6">
                        <div className="heading text-center mb-50">
                          <h2 className="mb-4">Our Team</h2>
                          <p>Today’s users expect effortless experiences. Don’t let essential people and processes stay stuck in the past. Speed it up, skip the hassles</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="team-block mb-5 mb-lg-0">
                          <img src="assets/images/team/03-1.jpg" alt="" className="img-fluid w-100" />
                          <h4 className="mt-4 mb-0">John Marshal</h4>
                          <p>Founder</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="team-block mb-5 mb-lg-0">
                          <img src="assets/images/team/01-3.jpg" alt="" className="img-fluid w-100" />
                          <h4 className="mt-4 mb-0">Marshal Root</h4>
                          <p>Marketing Head</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="team-block mb-5 mb-lg-0">
                          <img src="assets/images/team/03-1.jpg" alt="" className="img-fluid w-100" />
                          <h4 className="mt-4 mb-0">Siamon john</h4>
                          <p>Co-ordinator</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="team-block">
                          <img src="assets/images/team/01-3.jpg" alt="" className="img-fluid w-100" />
                          <h4 className="mt-4 mb-0">Rishat Ahmed</h4>
                          <p>Senior Developer</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="section awards bg-light">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="why-content">
                          <h2 className="mb-4">Honors and awards</h2>
                          <p className="mb-5">Dicta cupiditate, incidunt quia obcaecati itaque cumque, nostrum ipsum est voluptatibus, porro
                            provident a quam quibusdam. Ducimus possimus, nesciunt minima magni aspernatur.</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-3 col-sm-4">
                        <div className="award-img-block mb-4 mb-lg-0">
                          <div className="award-img">
                            <img src="assets/images/about/02.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4">
                        <div className="award-img-block mb-4 mb-lg-0">
                          <div className="award-img">
                            <img src="assets/images/about/03.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4">
                        <div className="award-img-block mb-4 mb-lg-0">
                          <div className="award-img">
                            <img src="assets/images/about/04.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4">
                        <div className="award-img-block mb-4 mb-lg-0">
                          <div className="award-img">
                            <img src="assets/images/about/05.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4">
                        <div className="award-img-block mb-4 mb-lg-0">
                          <div className="award-img">
                            <img src="assets/images/about/06.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-4">
                        <div className="award-img-block">
                          <div className="award-img">
                            <img src="assets/images/about/07.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section> */}
                {/*<section className="section testimonial">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="heading">
                          <h2 className="mb-4">What they say about us</h2>
                          <p>They are truely amazing in the industry ,molestiae expedita aspernatur officia, similique minima laudantium. Corrupti ipsa perspiciatis commodi nesciunt rerum mollitia enim voluptate!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12 testimonial-wrap">
                        <div className="testimonial-block">
                          <p>
                            Maiores quasi placeat harum itaque, ducimus cumque quas expedita hic. Qui ullam iste provident mollitia expedita facilis fuga repellat.
                          </p>
                          <div className="client-info d-flex align-items-center">
                            <div className="client-img">
                              <img src="assets/images/team/testimonial1.jpg" alt="" className="img-fluid" />
                            </div>
                            <div className="info">
                              <h6>John Partho</h6>
                              <span>Newyork USA</span>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-block">
                          <p>
                            Maiores quasi placeat harum itaque, ducimus cumque quas expedita hic. Qui ullam iste provident mollitia expedita facilis fuga repellat.
                          </p>
                          <div className="client-info d-flex align-items-center">
                            <div className="client-img">
                              <img src="assets/images/team/testimonial2.jpg" alt="" className="img-fluid" />
                            </div>
                            <div className="info">
                              <h6>John Partho</h6>
                              <span>Newyork USA</span>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-block">
                          <p>
                            Maiores quasi placeat harum itaque, ducimus cumque quas expedita hic. Qui ullam iste provident mollitia expedita facilis fuga repellat.
                          </p>
                          <div className="client-info d-flex align-items-center">
                            <div className="client-img">
                              <img src="assets/images/team/testimonial1.jpg" alt="" className="img-fluid" />
                            </div>
                            <div className="info">
                              <h6>John Partho</h6>
                              <span>Newyork USA</span>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-block">
                          <p>
                            Maiores quasi placeat harum itaque, ducimus cumque quas expedita hic. Qui ullam iste provident mollitia expedita facilis fuga repellat.
                          </p>
                          <div className="client-info d-flex align-items-center">
                            <div className="client-img">
                              <img src="assets/images/team/testimonial2.jpg" alt="" className="img-fluid" />
                            </div>
                            <div className="info">
                              <h6>John Partho</h6>
                              <span>Newyork USA</span>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-block">
                          <p>
                            Maiores quasi placeat harum itaque, ducimus cumque quas expedita hic. Qui ullam iste provident mollitia expedita facilis fuga repellat.
                          </p>
                          <div className="client-info d-flex align-items-center">
                            <div className="client-img">
                              <img src="assets/images/team/testimonial1.jpg" alt="" className="img-fluid" />
                            </div>
                            <div className="info">
                              <h6>John Partho</h6>
                              <span>Newyork USA</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>*/}

                {/* section Counter End  */}
                <section className="section service-2">
                  <div className="container">
                    <p>Our comprehensive range of services encompasses the entire spectrum of IT, spanning from strategic planning and meticulous execution to ongoing support and maintenance. By immersing ourselves in our clients' operations, we forge a deep understanding of their unique business needs, enabling us to craft tailored solutions that seamlessly blend innovation with practicality.</p>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="service-img mb-5 mb-lg-0">
                          <img src="assets/images/service/service-1.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="service-info ">
                          <span className="text-color font-weight-bold">01</span>
                          <h3 className="text-md mb-4 mt-2">
                            Custom Software development
                          </h3>
                          <p>At Apricot, we tailor the software development process to your specific business needs to deliver high-quality software on time and within budget.
                            <br/>
                            We create software with long-term business value – tailored uniquely to your business processes and adjustable to future needs.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="content-padding position-relative">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="service-info mb-5 mb-lg-0">
                            <span className="text-color font-weight-bold">02</span>
                            <h3 className="text-md mb-4 mt-2">
                              Software Maintenance
                            </h3>
                            <p>We ensure smooth functioning and relevancy of your software via continuous performance monitoring, proactive optimization and fast issue resolution, delivery of new features and integrations.</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="service-img">
                            <img src="assets/images/service/service-3.jpg" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="service-img mb-5 mb-lg-0">
                          <img src="assets/images/service/service-4.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="service-info">
                          <span className="text-color font-weight-bold">03</span>
                          <h3 className="text-md mb-4 mt-2">
                            Application Services
                          </h3>
                          <p>Application support and maintenance services are aimed to ensure that your apps are highly available, reliable and keep up with your ever-evolving business needs.<br/>

                            Apricot provides app support and maintenance services. We help our customers from 30+ industries free up their IT staff and fully focus on the creative side of the business to achieve sustainable growth.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="section case-study bg-light">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-8">
                        <div className="case-study-content text-center mb-5">
                          <h2 className="mb-4">How we work</h2>
                        </div>
                      </div>
                    </div>
                    <div className="case-timeline">
                      <div className="case-timeline-divider" />
                      <div className="case-timeline-dot" />
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          <div className="case-img ">
                            <img src="assets/images/about/business.svg" alt="" className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="case-content">
                            <h4 className="mb-3">Business analysis</h4>
                            <p align="justify">Our business analytics transform your needs into prioritized software requirements and aid in conceptualizing your software.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="case-timeline">
                      <div className="case-timeline-divider" />
                      <div className="case-timeline-dot" />
                      <div className="row align-items-center">  
                        <div className="col-lg-6 order-2 order-lg-1">
                          <div className="case-content">
                            <h4 className="mb-3">Software development process and management </h4>
                            <p align="justify">Our software development process and management utilize an iterative development approach, enabling us to launch the first software version within 3-4 months and continuously evolve it every 1-4 weeks.</p>
                          </div>
                        </div>
                        <div className="col-lg-6  order-1 order-lg-2">
                          <div className="case-img">
                            <img src="assets/images/about/devlopment.svg" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="case-timeline">
                      <div className="case-timeline-divider" />
                      <div className="case-timeline-dot" />
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          <div className="case-img">
                            <img src="assets/images/about/qa.svg" alt="" className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="case-content">
                            <h4 className="mb-3">QA & Testing</h4>
                            <p align="justify">To ensure software quality, we employ a comprehensive testing strategy that includes unit tests, code reviews, functionality, performance, usability, integration, compatibility, and security testing.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="case-timeline">
                      <div className="case-timeline-divider" />
                      <div className="case-timeline-dot" />
                      <div className="row align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                          <div className="case-content">
                            <h4 className="mb-3">Risk management</h4>
                            <p align="justify">We define and estimate risks related to the project budget, delivery time, cybersecurity, new technologies and personnel. Then, we prepare a mitigation plan for each risk, monitor risks and report to responsible project stakeholders to keep all parties informed about existing risks and their states and ensure the consistency of risk management actions.</p>
                          </div>
                        </div>
                        <div className="col-lg-6  order-1 order-lg-2">
                          <div className="case-img">
                            <img src="assets/images/about/risk.svg" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="case-timeline">
                      <div className="case-timeline-divider" />
                      <div className="case-timeline-dot" />
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          <div className="case-img">
                            <img src="assets/images/about/change.svg" alt="" className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="case-content">
                            <h4 className="mb-3">Change management</h4>
                            <p align="justify">We review the required software changes with all parties concerned, analyzing how the changes will impact software business logic and adapting it accordingly before any change implementation.</p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="case-timeline">
                      <div className="case-timeline-divider" />
                      <div className="case-timeline-dot" />
                      <div className="row align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                          <div className="case-content">
                            <h4 className="mb-3">Project delivery</h4>
                            <p align="justify">Upon completion of the software, our customers retain complete ownership of the intellectual property rights. Additionally, we provide maintenance and support services to facilitate continuous software improvement.</p>
                            {/* <ul className="list-unstyled mt-4 mb-5">
                              <li><i className="ti-check mr-3" />Turn-key software.</li>
                              <li><i className="ti-check mr-3" />Documentation (technical requirements and design).</li>
                              <li><i className="ti-check mr-3" />User manual.</li>
                              <li><i className="ti-check mr-3" />Setup program.</li>
                              <li><i className="ti-check mr-3" />Installation and maintenance instructions.</li>
                              <li><i className="ti-check mr-3" />Source code.</li>
                              <li><i className="ti-check mr-3" />Warranty.</li>
                            </ul> */}
                            {/* <p align="justify">Our customers retain full intellectual property rights over software.</p>
                            <p align="justify">Upon software completion, we offer maintenance and support services to help continuously improve software.</p> */}
                          </div>
                        </div>
                        <div className="col-lg-6  order-1 order-lg-2">
                          <div className="case-img">
                            <img src="assets/images/about/delivery.svg" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p align="justify">We identify and assess risks related to project budget, delivery time, cybersecurity, new technologies, and personnel. Mitigation plans are created for each risk, and ongoing monitoring and reporting keep stakeholders informed. We also analyze software changes' impact on business logic and make necessary adaptations before implementing any changes.</p>
                  </div>
                </section>
                <section className="video-block">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-12">
                        <div className="heading feature-list">
                          <h2 className="my-4">What our customer receives ?</h2>
                          <ul className="list-unstyled mt-4 mb-5">
                            <li><i className="ti-check mr-3" />Fully operational software solution.</li>
                            <li><i className="ti-check mr-3" />Technical requirements and design documentation.</li>
                            <li><i className="ti-check mr-3" />User manual for easy usage.</li>
                            <li><i className="ti-check mr-3" />Setup program for convenient installation.</li>
                            <li><i className="ti-check mr-3" />Instructions for installation and maintenance.</li>
                            <li><i className="ti-check mr-3" />Source code of the software.</li>
                            <li><i className="ti-check mr-3" />Warranty for added assurance.</li>
                            <li><i className="ti-check mr-3" />Full ownership of the intellectual property rights of the software.</li>
                            <li><i className="ti-check mr-3" />Maintenance and support services.</li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
            </div>
        );
    }
